.home {
  font-family: "Fellix-SemiBold";
  background: linear-gradient(to right, #d7e1ec, #ffffff);

  min-height: 100vh;

  &__note {
    height: 67px;
    width: 50%;
    margin: 70px auto;
    background-color: white;
    padding: 30px 50px;
    border-radius: 12px;
    box-shadow: 1px 2px 5px #707070;
    &--desc {
      margin: auto;
    }
    &--span {
      text-align: left;
      margin: auto;
      margin-top: 7px;
      font-size: 0.9rem;
      color: rgb(110, 110, 110);
      width: 80%;
    }
  }

  &__header {
    background-image: linear-gradient(-90deg, #ee3f7a, #f15b49, #f26931);
    padding: 10px;
    color: white;
    font-family: "Fellix-Bold";
    font-size: 1.2rem;
  }

  &__footer {
    background-image: linear-gradient(-90deg, #ee3f7a, #f15b49, #f26931);
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "Fellix-Bold";
    font-size: 1.2rem;

    margin-top: 30px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      background-color: rgba(251, 101, 30, 0.9);
    }
    &--link {
      color: white;
    }
    &--icon {
      padding-left: 12px;

      &:hover {
        padding-left: 20px;
      }
    }
  }

  &__link-nft {
    color: #f15b49;
    font-family: "Fellix-Bold";
  }

  &__link-nfr {
    color: #315ef2;
    font-family: "Fellix-Bold";
  }

  &__future {
    font-size: 0.93rem;
  }

  &__one-liner {
    font-size: 1.2rem;
    font-family: "Fellix-Bold";
    margin-bottom: 50px;
    background-image: linear-gradient(-90deg, #3b86ff, #3761a4, #4180ff);
    padding: 17px 0px;
    color: white;
  }

  &__logo-nfe {
    position: absolute;

    left: 30px;

    top: 10px;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    height: 120px;

    color: white;

    &--upper {
      font-family: "Fellix-Bold";
      font-weight: 800;
      font-size: 2.5rem;
    }
    &--lower {
      font-family: "Fellix-Regular";
      letter-spacing: 0.27rem;
      font-size: 2rem;
    }
    &--lower-education {
      font-family: "Fellix-Regular";
      letter-spacing: 0.27rem;
      font-size: 1.34rem;
    }
  }

  &__readers {
    background-color: white;
  }

  &__logo-nft {
    padding-top: 30px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-image: linear-gradient(-90deg, #ee3f7a, #f15b49, #f26931);
  }

  &__logo-pmr {
    padding: 14px 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-image: linear-gradient(-90deg, #8e3fee, #5a49f1, #6831f2);
  }

  &__logo-nfr {
    padding-top: 30px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-image: linear-gradient(-90deg, #3f65ee, #77b7ff, #315ef2);
  }

  &__products-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 55px 70px;
    overflow-x: scroll;
    max-width: 1200px;
    margin: auto;
  }

  &__card {
    width: 325px;
    height: 430px;
    box-shadow: 1px 2px 5px #707070;
    border-radius: 8px;
    padding: 0px;
    margin: 0px 20px;
    background-color: #fff;
    position: relative;

    &--label {
      padding: 5px 0px;
      position: absolute;
      top: -34px;
      left: 107px;
      text-align: center;
    }

    &--label-game {
      top: -38px;
      left: 90px;
      font-size: 1rem;
      font-family: "Fellix-Bold";
    }

    &--middle-nft {
      height: 147px;

      box-shadow: 0px 4px 4px -4px rgb(101, 106, 121);
    }
    &--middle-nfr {
      height: 147px;
      box-shadow: 0px 4px 4px -4px rgb(123, 133, 154);
    }
  }

  &__reviews {
    width: 325px;
    margin-top: 10px;
  }
  &__books {
    width: 325px;
  }

  &__moonrock-logo {
    width: 120px;
  }

  &__demo {
    margin: 20px auto 0px;
    font-family: "Fellix-Bold";

    color: #5a49f1;
  }

  &__game-span {
    font-family: "Fellix-Bold";

    color: #5a49f1;
  }

  &__vimeo {
    width: 325px !important;
    height: 155px !important;
    border-radius: 0px;
    box-shadow: 0px 4px 2px -2px rgb(154, 154, 154);
  }

  &__list {
    display: flex;
    flex-direction: column;

    &--row {
      font-size: 0.9rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 20px 0px;
      text-align: left;

      &:first-of-type {
        padding-top: 43px;
      }

      &--game {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 14px 20px 0px;
        text-align: left;
        font-size: 0.9rem;
      }
    }

    &--icon {
      margin-right: 12px;
      margin-left: 12px;
      font-size: 0.4rem;
    }
  }

  &__cm-wrapper {
    height: 147px;
    position: relative;
    background-image: linear-gradient(-90deg, #ee3f7a, #f15b49, #f26931);

    box-shadow: 0px 6px 4px -4px rgb(79, 79, 79);
  }

  &__cm {
    height: 80px;
    box-shadow: 1px 5px 10px rgb(133, 38, 0);
    border-radius: 8px;
    padding: 20px 60px;
    position: absolute;
    left: calc(50% - 137px);
    background-color: white;

    &--1 {
      animation-name: first;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    &--2 {
      animation-name: second;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      z-index: 1;
    }
    &--img {
      height: 100px;
      border-radius: 8px;
    }
  }

  &__cockroach {
    height: 250px;
    width: 80%;
    margin: 40px auto;
    background-color: white;
    padding: 30px 50px;
    border-radius: 12px;
    box-shadow: 1px 2px 5px #707070;

    &--img {
      height: 200px;
      margin: auto;
      background-image: url("https://nft-image-pool.s3.us-east-2.amazonaws.com/no-fuss-education/cockroach.svg");
      background-repeat: no-repeat;
    }

    &--desc {
      margin-left: 23%;
      text-align: left;
      transform: translateY(-100px);
      font-size: 1.2rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .home {
    &__cm-wrapper {
      height: 219px;
    }

    &__logo-nfe {
      position: absolute;

      left: 30%;

      top: 130px;
    }

    &__products-wrapper {
      flex-direction: column;
      padding: 0px;
    }

    &__card {
      margin: 30px auto;
    }

    &__cockroach {
      width: 75%;
      padding: 30px;
      margin: 10px auto 40px;

      &--img {
        height: 150px;
      }
    }

    &__note {
      height: 130px;
      margin: 30px auto 40px;
    }
  }
}

@keyframes first {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes second {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
