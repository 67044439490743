@import "./Partials/home";

@font-face {
  font-family: "Fellix-Regular";
  src: url("../Fonts/Fellix-Regular.woff") format("woff");
}

@font-face {
  font-family: "Fellix-Medium";
  src: url("../Fonts/Fellix-Medium.woff") format("woff");
}

@font-face {
  font-family: "Fellix-SemiBold";
  src: url("../Fonts/Fellix-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Fellix-Bold";
  src: url("../Fonts/Fellix-Bold.woff") format("woff");
}
